@charset "UTF-8";
/* import all utilities scss */
/*
 * @ Variables
 *
*/
/*
 *  Farah Typograpy
*/
/*
 *  Farah base Font sizex
*/
/*
 * H1
*/
/* Russian */
/*Chinese*/
/*
 * H2
*/
/* Russian */
/*Chinese*/
/*
 * H3
*/
/* Russian */
/*Chinese*/
/*
/*
 * H4
*/
/*Russian*/
/*Chinese*/
/*
 * H5
*/
/* Russian */
/*Chinese*/
/*
 * H6
*/
/* Russian */
/*Chinese*/
/*
 * body-1
*/
/*
 * body-2
*/
/*
 * body-3
*/
/*
 * body-4
*/
/*
 *  Base Container
*/
/*
 *  Z-index Of components
*/
/* Please do not give z-index more than these z-Index */
/*
 *  Farah desktop font Sizes
*/
/*
 *  Farah mobile font Sizes
*/
/*
 *  Radius
*/
/*
 *  Use Defaults
*/
/*
 *  Base Alignments
*/
/*Hero Map component*/
/*input range*/
/**
    New Summary Cart
*/
/*
 * New Design System - 2025
 *
*/
/* Colors */
/* footer */
/* Editoiral Grid */
/*Carousel with Preview*/
/*
*  My profile
*/
/*
 *  Generic Colors
*/
/*
 *  Farah Base primary colors
*/
/*
 *  Farah Supporting colors
*/
/*
 *  Yas WaterWorld Base primary colors
*/
/*
 *  Yas Island Base primary colors
*/
/*
 *  Yas Ferrari World Base primary colors
*/
/*
 * Farah Font/buttons Color
*/
/*
 * Farah Font/buttons Color
*/
/**
Hotel Booking
*/
/**
tab with placeholder
*/
/**
tab feature list
*/
/*media carousel overlay*/
/*calendar dropdown*/
/* Trip Advisor Color */
/*overlay*/
/* Flash-Sale */
/*
* form placeholder color variables
*/
/*
 * @ Mixins
 *
*/
/*
* Background Image
*/
/*
* Configure vendor
*/
/*
* Line height font
*/
/*
* Clearfix
*/
/*
* Text Nowrap
*/
/*
* Font Smoothing
*/
/*
* Transition
  usage example
    a{
    color: gray;
    @include transition(color .3s ease);
    &:hover {
      color: black;
    }
}
*/
/*
* Background Gradient
*/
/*
* Background Horizontal
*/
/*
* Background Radial
*/
/*
* Background Size
*/
/*
* Background Color Opacity
*/
/*
* Border Radius
*/
/*
* Border Radius Separate
*/
/*
* Box
*/
/*
* Box Rgba
*/
/*
* Box Shadow
*/
/*
* Box Sizing
*/
/*
* Columns
*/
/*
* Flex
*/
/*
* Flip
*/
/*
* Opacity
*/
/*
* Resize
*/
/*
* Rotate
*/
/*
* Text Shadow
*/
/*
* Transform
*/
/*
* Keyframes
 Usage Example
  @include keyframes(slide-down) {
    0% { opacity: 1; }
    90% { opacity: 0; }
  }
*/
/*
* Animation
Usage Example
 .element {
    @include animation('slide-down 5s 3');
  }
*/
/*
* Widescreen
*/
/*
* Desktop (PC's)
*/
/*
* Desktop (PC's)
*/
/*
* Wide screen Desktop (PC's)
*/
/*
  * large screen Desktop (PC's)
  */
/*
* Tablet
*/
/*
  * TabletOnly
  */
/*
* Mobile
*/
/*
* upto Tablet
*/
/*
* No Screen
*/
/**
**  how to Use Mixin for tablet-landscape
**
.example{
  left: 35px;
  @include tablet-landscape{
      left: 100px;
  }
}
**/
/**
*   Use Arrow inside the :after and :before pseudo elements
*   and change the position as per needed
*   @include arrows('right', 7px, 7px, #000);
**/
/*
* Font Face
*/
/*
* Placeholder Styling
*/
/*half circle left right for ticket tiles*/
/**
* Desc: Create equilateral triangle used in header on hover and focus
*/
/*
* Breakpoint
* @ Variables
*
*/
/*
    i/p -
        $available-maxwidth : maximum available width
        $column-width : width of 1 column
        $col-count : column no who's width has to be calculated
    o/p -
        column width of desired column no in %
    example -
        $available-max-width = 13200 , $column-width = 12
        avl width = 1320px = 100%
        width of 1px in % = 100 *(1 / 1320px)
        1 col-width in % = col-width * width of 1px in %
        86px(col-width) in % = 86 * (100 *(1 / 1320px)) = 6.51515%
*/
/*
    o/p -
        column width of desired column no in %
    i/p -
        $breakpoint : viewport at which width needs to be calculated
        $col-count : column no who's width has to be calculated
*/
/*
    o/p -
        returns value of gutter for 1 column in % as per the viewport
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            $width: width of column w.r.t col-count
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
    o/p -
        returns
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            margin-left: left margin = width of 2 columns + gutter
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
 * @ Text
 *
*/
/* lang ar */
/* lang ru */
/**
* Heading 5 has two versions
* Bold and Black
* Use heading-ru-5-bold and heading-ru-5-black
**/
/**
* Heading 6 has two versions
* Bold and Black
* Use heading-ru-6-bold and heading-ru-6-black
**/
/* lang zh */
/**
* Heading 5 has two versions
* Bold and Black
* Use heading-zh-5-bold and heading-zh-5-black
**/
/**
* Heading 6 has two versions
* Bold and Black
* Use heading-zh-6-bold and heading-zh-6-black
**/
/* lang all */
:root {
  --font-weight-base: 400;
}

body.lang-ar {
  --font-weight-base: 700;
}

/*
 * @ Gradients
 *
*/
/*
 * Line height font
 */
.c-tabs-with-placeholder {
  max-width: 1366px;
  margin: 0 auto;
}
.c-tabs-with-placeholder .component-title {
  max-width: 1320px;
  margin: auto;
  text-align: center;
}
.c-tabs-with-placeholder .component .w--content {
  width: 100%;
  max-width: none;
}
.c-tabs-with-placeholder .component-title {
  text-align: center;
}
.c-tabs-with-placeholder .v-tabs-dropdown {
  width: 95.2380952381%;
  padding-left: 2.380952381%;
  padding-right: 2.380952381%;
  margin: 30px auto 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .c-tabs-with-placeholder .v-tabs-dropdown {
    width: 47.311827957%;
    margin-left: 1.3440860215%;
    margin-right: 1.3440860215%;
    margin: 30px auto 0;
  }
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder .v-tabs-dropdown {
    width: 98.1818181818%;
    margin-left: 0.9090909091%;
    margin-right: 0.9090909091%;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1366px) {
  .c-tabs-with-placeholder .v-tabs-dropdown {
    width: 81.5151515152%;
    margin-left: 0.9090909091%;
    margin-right: 0.9090909091%;
    padding: 0;
    margin: 0 auto;
    margin-top: 40px;
  }
}
.c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu-mobile .tabs-menu--links {
  border-bottom: 4px solid #efefef;
  color: #333;
}
.c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu-mobile .tabs-menu--links.is-active {
  border-bottom: 4px solid #333;
}
.c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown {
  font-family: "GothamBlack", "Open Sans", Arial, sans-serif;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1.83;
  letter-spacing: 2px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  width: 95.2380952381%;
  margin-left: 2.380952381%;
  margin-right: 2.380952381%;
  color: #333;
  font-family: "GothamBlack", "Open Sans", Arial, sans-serif;
  font-weight: 900;
  line-height: 1.4;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}
@media only screen and (min-width: 1366px) {
  .c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}
@media (min-width: 768px) {
  .c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown {
    width: 97.311827957%;
    margin-left: 1.3440860215%;
    margin-right: 1.3440860215%;
    font-size: 14px;
  }
}
.c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown-head-length {
  display: none;
}
.c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown-carrot {
  top: 18px;
}
.c-tabs-with-placeholder .v-tabs-dropdown .heading-6 {
  font-weight: 900;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
.c-tabs-with-placeholder .v-tabs-dropdown .heading-6.is-active {
  font-family: "GothamBlack", "Open Sans", Arial, sans-serif;
  font-weight: 900;
}
@media (min-width: 768px) {
  .c-tabs-with-placeholder .v-tabs-dropdown .heading-6 {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder .v-tabs-dropdown .heading-6 {
    font-family: "GothamBold", "Open Sans", Arial, sans-serif;
    font-size: 12px;
    font-size: 0.75rem;
    line-height: 1.83;
    letter-spacing: 2px;
    /* Small devices (tablets, 768px and up) */
    /* Medium devices (desktops, 1024px and up) */
    /* Medium devices (desktops, 1366px and up) */
    font-weight: 700;
  }
}
@media only screen and (min-width: 1024px) and (min-width: 768px) {
  .c-tabs-with-placeholder .v-tabs-dropdown .heading-6 {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) and (min-width: 1024px) {
  .c-tabs-with-placeholder .v-tabs-dropdown .heading-6 {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) and (min-width: 1366px) {
  .c-tabs-with-placeholder .v-tabs-dropdown .heading-6 {
    font-size: 12px;
    font-size: 0.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ru .c-tabs-with-placeholder .v-tabs-dropdown .heading-6 {
    font-size: 12px;
    font-size: 0.75rem;
    letter-spacing: 2px;
    line-height: 14px;
    font-family: "NotoSansBold", "Open Sans", Arial, sans-serif;
    font-weight: bold;
  }
  .lang-zh .c-tabs-with-placeholder .v-tabs-dropdown .heading-6 {
    font-size: 12px;
    font-size: 0.75rem;
    letter-spacing: 2px;
    line-height: 14px;
    font-family: "PingFang SC", "STHeiti", "华文黑体", "Microsoft YaHei", "微软雅黑", "GothamBold", "Open Sans", Arial, sans-serif;
    font-weight: bold;
    font-weight: bold;
  }
  .c-tabs-with-placeholder .v-tabs-dropdown .heading-6.is-active {
    font-weight: 900;
  }
}
.c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu {
  width: 95.2380952381%;
  margin-left: 2.380952381%;
  margin-right: 2.380952381%;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu {
    width: 88.9784946237%;
    margin-left: 1.3440860215%;
    margin-right: 1.3440860215%;
    margin: 0 auto;
  }
}
.c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links {
  opacity: 1;
  padding: 15px 20px;
  line-height: normal;
}
.c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links span {
  font-family: "GothamBold", "Open Sans", Arial, sans-serif;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 14px;
  letter-spacing: 2px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  color: #333;
  font-weight: normal;
  letter-spacing: 1.6px;
  font-family: "GothamLight", "Open Sans", Arial, sans-serif;
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links span {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links span {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}
@media only screen and (min-width: 1366px) {
  .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links span {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links span {
    letter-spacing: 1px;
    font-family: "GothamBold", "Open Sans", Arial, sans-serif;
  }
}
.c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links.is-active {
  background-color: #f6f6f6;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links.is-active {
    background-color: transparent;
  }
}
.c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links.is-active span {
  color: #000;
  font-weight: 400;
  letter-spacing: 1.8px;
  font-family: "GothamBlack", "Open Sans", Arial, sans-serif;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links.is-active span {
    color: #333;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu {
    width: 100%;
    padding: 0;
    margin: 0 auto;
    max-width: none;
  }
  .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links {
    padding: 15px 0;
    border-bottom: 6px solid #efefef;
  }
  .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links.is-active {
    color: #333;
    border-bottom: 6px solid #333;
  }
}
@media only screen and (min-width: 1366px) {
  .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links {
    padding: 18px;
  }
}
.c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu::before {
  border-radius: 4px;
}
.c-tabs-with-placeholder .tabs-panel-container {
  width: 95.2380952381%;
  padding-left: 2.380952381%;
  padding-right: 2.380952381%;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .c-tabs-with-placeholder .tabs-panel-container {
    width: 97.311827957%;
    margin-left: 1.3440860215%;
    margin-right: 1.3440860215%;
  }
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder .tabs-panel-container {
    width: 98.1818181818%;
    margin-left: 0.9090909091%;
    margin-right: 0.9090909091%;
  }
}
@media only screen and (min-width: 1366px) {
  .c-tabs-with-placeholder .tabs-panel-container {
    width: 81.5151515152%;
    margin-left: 0.9090909091%;
    margin-right: 0.9090909091%;
    padding: 0;
  }
}
.c-tabs-with-placeholder .tabs-panel-container .component .w--content {
  margin: 0 auto;
}
.c-tabs-with-placeholder .tabs-panel-container .c-editorial-list {
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .c-tabs-with-placeholder .tabs-panel-container .c-editorial-list--title {
    margin-left: 0;
    margin-right: auto;
  }
  .c-tabs-with-placeholder .tabs-panel-container .c-editorial-list--bodycopy {
    margin-left: auto;
    margin-right: 0;
  }
}
.c-tabs-with-placeholder .tabs-panel-container .c-collapsible-content {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder .tabs-panel-container .c-collapsible-content--title {
    margin-left: 0;
    margin-right: auto;
  }
  .c-tabs-with-placeholder .tabs-panel-container .c-collapsible-content--bodycopy {
    margin-left: auto;
    margin-right: 0;
  }
  .c-tabs-with-placeholder .tabs-panel-container .c-collapsible-content--bodycopy--no-title {
    /* Medium devices (desktops, 1024px and up) */
  }
}
@media only screen and (min-width: 1024px) and (min-width: 1024px) {
  .c-tabs-with-placeholder .tabs-panel-container .c-collapsible-content--bodycopy--no-title {
    margin: 0px auto;
  }
}
.c-tabs-with-placeholder .tabs-panel-container .renew-section .yas-custom-radio input:checked::before {
  background-color: #fee233;
}
.c-tabs-with-placeholder .tabs-panel-container .renew-section .form-control .form-checkbox-fancy:after {
  background-color: #fee233;
}
.c-tabs-with-placeholder .tabs-panel-container .renew-section .annual-pass-section .annual-pass-details .annual-pass-row .left-section {
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1023px) {
  .c-tabs-with-placeholder .tabs-panel-container .renew-section .annual-pass-section .annual-pass-details .annual-pass-row .left-section {
    margin-bottom: 10px;
  }
}
.c-tabs-with-placeholder .tabs-panel-container .renew-section .annual-pass-section .upper-border.brand-color {
  background: #fbdc00;
}
.c-tabs-with-placeholder .tabs-panel-container .renew-section .annual-pass-section .annual-pass-details .header-brand-color {
  background: #fffce8;
}
.c-tabs-with-placeholder .tabs-panel-container .renew-section .btn-wrapper .btn-subscribe a {
  color: #333;
}
.lang-ar .c-tabs-with-placeholder .tabs-panel-container .renew-section .btn-wrapper .btn-subscribe a {
  color: #000;
}
.c-tabs-with-placeholder .tabs-panel-container .renew-section .btn-wrapper .btn-subscribe.renew-mobile {
  background: #ffda0a;
}
.c-tabs-with-placeholder .tabs-panel-container .renew-section .btn-wrapper .btn-subscribe.renew-mobile a {
  font-family: "GothamBlack", "Open Sans", Arial, sans-serif;
  line-height: 29px;
  font-weight: normal;
  color: #000;
}
.lang-ar .c-tabs-with-placeholder .tabs-panel-container .renew-section .btn-wrapper .btn-subscribe.renew-mobile a {
  font-weight: 700;
}
.c-tabs-with-placeholder.component .tabs-panel-container {
  margin: 0 auto;
  /* Medium devices (desktops, 1366px and up) */
}
@media only screen and (min-width: 1366px) {
  .c-tabs-with-placeholder.component .tabs-panel-container {
    max-width: 1366px;
  }
}
.c-tabs-with-placeholder .c-collapsible-content .c-collapsible-content--title:first-of-type {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder .c-collapsible-content .c-collapsible-content--title:first-of-type {
    border-top: none;
  }
}
.c-tabs-with-placeholder .c-collapsible-content .c-collapsible-content--bodycopy:first-of-type {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder .c-collapsible-content .c-collapsible-content--bodycopy:first-of-type {
    border-top: none;
  }
}
.c-tabs-with-placeholder .c-collapsible-content .c-collapsible-content--bodycopy:last-of-type .accordian--list-item:last-child {
  border: 0;
}
.c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu.w--content {
  background-color: #fffced;
  color: #333;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu.w--content {
    background: transparent;
  }
}
.c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu.w--content .tabs-menu--links {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu.w--content .tabs-menu--links {
    color: #333;
  }
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu.w--content .tabs-menu--links:after {
    background: #f6f6f6;
    box-shadow: 1px -2px 4px 0px rgba(0, 0, 0, 0.33);
  }
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu.w--content .tabs-menu--links:nth-child(1) {
    background: transparent;
  }
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu.w--content .tabs-menu--links:nth-child(even):after {
    background: #fffced;
  }
}
.c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu.w--content .tabs-menu--links span,
.c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu.w--content .tabs-menu--links a {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.71;
  letter-spacing: 0.1px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  font-weight: 700;
  line-height: 0;
}
@media (min-width: 768px) {
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu.w--content .tabs-menu--links span,
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu.w--content .tabs-menu--links a {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu.w--content .tabs-menu--links span,
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu.w--content .tabs-menu--links a {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu.w--content .tabs-menu--links span,
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu.w--content .tabs-menu--links a {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu.w--content .tabs-menu--links:nth-child(1) {
  background: #ffdf00;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu.w--content .tabs-menu--links:nth-child(1) {
    background: transparent;
  }
}
.c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu.w--content .tabs-menu--links:nth-child(2) {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu.w--content .tabs-menu--links:nth-child(2) {
    z-index: 6;
  }
}
.c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu.w--content .tabs-menu--links:nth-child(3) {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu.w--content .tabs-menu--links:nth-child(3) {
    z-index: 5;
  }
}
.c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu.w--content .tabs-menu--links.is-active {
  background: #ffdf00;
  color: #333;
  /* Medium devices (desktops, 1024px and up) */
}
.c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu.w--content .tabs-menu--links.is-active:after {
  background: #ffdf00;
}
.c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu.w--content .tabs-menu--links.is-active span,
.c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu.w--content .tabs-menu--links.is-active a {
  font-weight: 900;
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu.w--content .tabs-menu--links.is-active {
    background: transparent;
    z-index: 7;
  }
}

.lang-ar .c-tabs-with-placeholder .component-title {
  max-width: 1320px;
  margin: auto;
  text-align: center;
}
.lang-ar .c-tabs-with-placeholder.component.v-tabs-hotel-booking-widget {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .c-tabs-with-placeholder.component.v-tabs-hotel-booking-widget {
    top: 0px;
  }
}
.lang-ar .c-tabs-with-placeholder .tab-menu-dropdown-container .tab-menu-dropdown {
  left: auto;
  right: 22px;
}
.lang-ar .c-tabs-with-placeholder .tab-menu-dropdown-container .tab-menu-dropdown-carrot {
  right: auto;
  left: 20px;
}
.lang-ar .c-tabs-with-placeholder .tabs-menu:before {
  right: auto;
  left: 20px;
}
.lang-ar .c-tabs-with-placeholder .tabs-menu li {
  text-align: right;
  /* Medium devices (desktops, 1024px and up) */
  padding-right: 20px;
}
@media only screen and (min-width: 1024px) {
  .lang-ar .c-tabs-with-placeholder .tabs-menu li {
    text-align: center;
  }
}
.lang-ar .c-tabs-with-placeholder .tabs-menu li.heading-6 span {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: bold;
  /* < 768px */
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  font-weight: 400;
}
@media (min-width: 320px) and (max-width: 767px) {
  .lang-ar .c-tabs-with-placeholder .tabs-menu li.heading-6 span {
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 22px;
    line-height: 1.375rem;
    letter-spacing: 0;
  }
}
@media (min-width: 768px) {
  .lang-ar .c-tabs-with-placeholder .tabs-menu li.heading-6 span {
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 12px;
    line-height: 0.75rem;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .c-tabs-with-placeholder .tabs-menu li.heading-6 span {
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 22px;
    line-height: 1.375rem;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ar .c-tabs-with-placeholder .tabs-menu li.heading-6 span {
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 22px;
    line-height: 1.375rem;
  }
}
.lang-ar .c-tabs-with-placeholder .tabs-menu li.is-active span {
  font-weight: 900;
}
.lang-ar .c-tabs-with-placeholder .c-editorial-list {
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .lang-ar .c-tabs-with-placeholder .c-editorial-list--title {
    margin-left: auto;
    margin-right: 0;
  }
  .lang-ar .c-tabs-with-placeholder .c-editorial-list--bodycopy {
    margin-left: 0;
    margin-right: auto;
  }
}
.lang-ar .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links span,
.lang-ar .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links a {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  letter-spacing: 0;
  /* < 768px */
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  font-weight: 400;
  line-height: 0;
}
@media (min-width: 320px) and (max-width: 767px) {
  .lang-ar .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links span,
  .lang-ar .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links a {
    font-size: 16px;
    font-size: 1rem;
    line-height: 28px;
    line-height: 1.75rem;
  }
}
@media (min-width: 768px) {
  .lang-ar .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links span,
  .lang-ar .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links a {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 24px;
    line-height: 1.5rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links span,
  .lang-ar .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links a {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 24px;
    line-height: 1.5rem;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ar .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links span,
  .lang-ar .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links a {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 24px;
    line-height: 1.5rem;
  }
}
.lang-ar .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links.is-active span,
.lang-ar .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links.is-active a {
  font-weight: 700;
}